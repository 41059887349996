import React from 'react';
import SectionBox from '../../components/SectionBox';
import '../../styles/resources/content.scss';
import Header from '../../components/Header';

/**
 * Content item for resources
 */
const HandoutsFamilies = () => (
  <div>
    <Header short backgroundImage={require('../../images/families.jpg')} backgroundPosition="center 60%">
      <h1>Resources for Families</h1>
    </Header>
    <div className="resources-content content-item">
      <SectionBox>
        <h2>Valuable Resource Materials</h2>
        <ul>
          <li><a href="/documents/Representing_Yourself_Judges_Perspective.pdf" target="_blank" rel="noopener noreferrer">Representing Yourself in Court: A Brief Guide from a Judge's Perspective</a> by AFCC</li>
          <li><a href="/documents/Representing_Yourself_Mediation.pdf" target="_blank" rel="noopener noreferrer">A Brief Guide to Family Mediation for Parents who are Self-Represented</a> by AFCC</li>
          <li><a href="/documents/A_Guide_for_Consumers.pdf" target="_blank" rel="noopener noreferrer">Unbundling Legal Services: A Guide for Parents Seeking Affordable Legal Assistance in Family Separation Cases</a> by AFCC</li>
          <li><a href="/documents/AFCC_Coparenting_Communication_Guide.pdf" target="_blank" rel="noopener noreferrer">Co-Parenting Communication Guide</a> by AFCC</li>
          <li><a href="/documents/AFCC-O-Parenting-Plan-Guide.pdf" target="_blank" rel="noopener noreferrer">Parenting Plan Guide</a> by AFCC</li>
          <li><a href="/documents/AFCC-O-Parenting-Plan-Template.pdf" target="_blank" rel="noopener noreferrer">Parenting Plan Template</a> by AFCC</li>
          <li><a href="/documents/Child_Focused_Parenting_Time_Guide_Minnesota.pdf" target="_blank" rel="noopener noreferrer">Child-Focused Parenting Time Guide</a> by AFCC</li>
          <li><a href="/documents/Planning_for_Parenting_Time_Guide_Arizona.pdf" target="_blank" rel="noopener noreferrer">Planning for Parenting Time</a> by AFCC</li>
          <li><a href="/documents/Planning_for_Shared_Parenting.pdf" target="_blank" rel="noopener noreferrer">Planning for Shared Parenting</a> by AFCC</li>
        </ul>
      </SectionBox>
      <SectionBox>
        <h2>Valuable Websites</h2>
        <ul>
          <li><a href="http://www.uptoparents.org/" target="_blank" rel="noopener noreferrer">UpToParents.org</a> by AFCC</li>
          <li><a href="http://www.familieschange.ca/" target="_blank" rel="noopener noreferrer">Familieschange.ca</a> by AFCC</li>
        </ul>
      </SectionBox>
      <SectionBox>
        <h2>Valuable Resources from our Local Community</h2>
        <ul>
          <li><a href="http://www.childreninthemiddle.com/about.htm" target="_blank" rel="noopener noreferrer">Between Two Homes</a> by Bradley Craig, LMSW-IPR, CFLE</li>
          <li><a href="https://www.amazon.com/Considering-Divorce-Critical-Things-Need/dp/1491700084/ref=sr_1_2?qid=1675618370&refinements=p_27:Melinda+Eitzen&s=books&sr=1-2&text=Melinda+Eitzen" target="_blank" rel="noopener noreferrer">Considering Divorce?</a> by Melinda Eitzen, JD, Joanna Jadlow, CPA & Brenda Lee Roberts, LPC</li>
          <li><a href="https://www.fletcherphd.com/books" target="_blank" rel="noopener noreferrer">Parenting in the SmartZone</a> by Susan Fletcher, Ph.D.</li>
          <li><a href="https://www.fletcherphd.com/books" target="_blank" rel="noopener noreferrer">Working in the SmartZone</a> by Susan Fletcher, Ph.D.</li>
          <li><a href="https://www.amazon.com/Scattered-Focused-Strategies-Executive-Functioning/dp/1647396778" target="_blank" rel="noopener noreferrer">Scattered to Focused</a> by Zac Grisham, LPCS</li>
          <li><a href="https://www.jennifercounseling.com/meet-max" target="_blank" rel="noopener noreferrer">Meet Max Books</a> by Jennifer Leister, LPCS</li>
          <li><a href="https://happinessintheheartache.com/" target="_blank" rel="noopener noreferrer">One Princess, Two Castles</a> by Amy Tyson</li>
          <li><a href="https://www.warshak.com/" target="_blank" rel="noopener noreferrer">Divorce Poison</a> by Richard A. Warshak, Ph.D.</li>
        </ul>
      </SectionBox>
      <SectionBox>
        <h2>Valuable Materials from AFCC</h2>
        <ul>
          <li><a href="https://www.afccnet.org/Resource-Center/Family-Resources/FAQs-Parents-Ask-about-Separation-Divorce-and-Child-Custody" target="_blank" rel="noopener noreferrer">FAQs Parents Ask about Separation, Divorce and Child Custody</a> by AFCC</li>
          <li><a href="https://www.afccnet.org/Resource-Center/Family-Resources/FAQs-Separating-and-Divorcing-Parents-Ask-about-Legal-Matters" target="_blank" rel="noopener noreferrer">FAQs Separating and Divorcing Parents Ask about Legal Matters</a> by AFCC</li>
          <li><a href="https://www.afccnet.org/Resource-Center/Family-Resources/A-Guide-for-Joint-Custody-and-Shared-Parenting" target="_blank" rel="noopener noreferrer">A Guide for Joint Custody and Shared Parenting</a> by AFCC</li>
          <li><a href="https://www.afccnet.org/Resource-Center/Family-Resources/A-Guide-for-Stepparents" target="_blank" rel="noopener noreferrer">A Guide for Stepparents</a> by AFCC</li>
          <li><a href="https://www.afccnet.org/Resource-Center/Family-Resources/Understanding-Your-Childs-Needs-Information-for-Never-Married-Parents" target="_blank" rel="noopener noreferrer">Understanding Your Child’s Needs: Information for Never-Married Parents</a> by AFCC</li>
          <li><a href="https://www.afccnet.org/Resource-Center/Family-Resources/Is-Mediation-for-Us" target="_blank" rel="noopener noreferrer">Is Mediation for Us?</a> by AFCC</li>
          <li><a href="https://www.afccnet.org/Resource-Center/Family-Resources/Making-your-Parenting-Plan-Work" target="_blank" rel="noopener noreferrer">Making your Parenting Plan Work</a> by AFCC</li>
          <li><a href="https://www.afccnet.org/Resource-Center/Family-Resources/My-Mom-and-Dad-Are-Getting-a-Divorce" target="_blank" rel="noopener noreferrer">My Mom and Dad Are Getting a Divorce!</a> by AFCC</li>
          <li><a href="https://www.afccnet.org/Resource-Center/Family-Resources/Parents-Are-Forever" target="_blank" rel="noopener noreferrer">Parents Are Forever</a> by AFCC</li>
          <li><a href="https://www.afccnet.org/Resource-Center/Family-Resources/Preparing-for-your-Custody-Evaluation" target="_blank" rel="noopener noreferrer">Preparing for your Custody Evaluation</a> by AFCC</li>
          <li><a href="https://www.afccnet.org/Search-Results?search=understanding+the+parenting+coordination+process" target="_blank" rel="noopener noreferrer">Understanding the Parenting Coordination Process</a> by AFCC</li>
          <li><a href="https://www.afccnet.org/Resource-Center/Family-Resources/When-Parents-Relocate-Moving-Away-and-Long-Distance-Parenting" target="_blank" rel="noopener noreferrer">When Parents Relocate: Moving Away and Long-Distance Parenting</a> by AFCC</li>
        </ul>
      </SectionBox>
    </div>
  </div>
);

export default HandoutsFamilies;
