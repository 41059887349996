const memberList = [
  {
    name:    'Carrie Beaird, LPC-S, LCDC',
    image:   require('../images/headshots/carrie-beaird.jpg'),
    license: [
      'Licensed Professional Counselor-Supervisor',
      'Licensed Chemical Dependency Counselor',
    ],
    website:  ['co-parentingsolutions.com', 'cbcounseling.com'],
    email:    'cbcparenting@aol.com',
    phone:    '972-448-8797',
    services: [
      'Parenting Facilitation',
      'Parent Coordination',
      'Court Ordered Therapy',
      'Reunification Therapy',
      'Substance Abuse Evaluations',
    ],
  },
  {
    name:     'Christy Bradshaw Schmidt, LPC',
    image:    require('../images/headshots/christy-schmidt.jpg'),
    license:  ['Licensed Professional Counselor'],
    website:  ['txfamilylawforensics.com'],
    email:    'christy@txfamilylawforensics.com',
    phone:    '214-502-7224',
    services: [
      'Expert Witness',
      'Reviewing Testifying Expert',
      'Forensic Consultant',
      'Child Custody Evaluations',
      'Adoption Evaluations',
    ],
  },
  {
    name:     'Holly Winter Duke, MS, LPC, NCC',
    image:    require('../images/headshots/holly-winter.jpg'),
    license:  ['Licensed Professional Counselor'],
    website:  ['lewisvillecounselingservices.com'],
    email:    'hduke@lewisvillecounselingservices.com',
    phone:    '469-645-1375',
    services: [
      'Court Ordered Therapy',
      'Individual Therapy (ages 10 and up)',
      'Family Therapy',
      'Co-Parenting Support and Education',
    ],
  },
  {
    name:     'Susan Fletcher, Ph.D.',
    image:    require('../images/headshots/susan-fletcher.jpeg'),
    license:  ['Licensed Psychologist'],
    website:  ['FletcherPhD.com'],
    email:    'DrFletcher@FletcherPhD.com',
    phone:    '972-612-1188',
    services: [
      'Child Custody Evaluations',
      'Psychological Evaluations',
      'Work Product Reviews',
      'Testifying Expert',
      'Forensic Consultations',
      'Reunification Therapy',
    ],
  },
  {
    name:     'Michael Flynn, J.D., Ph.D.',
    image:    require('../images/headshots/michael-flynn.jpg'),
    license:  ['Psychologist/Attorney'],
    website:  ['michaelflynnjdphd.com'],
    email:    'docmflynn@michaelflynnjdphd.com',
    phone:    '682-990-6246',
    services: [
      'Licensing Board Defense',
      'Subpoena Assistance',
      'Deposition Assistance',
      'Business Entities',
      'Family Law Matters',
      'Wills',
    ],
  },
  {
    name:     'Zac Grisham, LPC-S, ADHD-CCSP',
    image:    require('../images/headshots/zac-grisham.jpg'),
    license:  ['Licensed Professional Counselor-Supervisor'],
    website:  ['cvcounselinggroup.com'],
    email:    'zgrisham@cvcounselinggroup.com',
    phone:    '214-298-1743',
    services: [
      'Court Ordered Therapy (ages 5 and up)',
      'Reunification Therapy',
      'ADHD Parent Training',
      'Family Therapy',
    ],
  },
  {
    name:     'Victoria Harvey, Ph.D., MSCJ',
    image:    require('../images/headshots/victoria-harvey.jpg'),
    license:  ['Licensed Psychologist'],
    website:  ['mitchellphd.com'],
    email:    'drvictoriaharvey@gmail.com',
    phone:    '972-330-7944',
    services: [
      'Psychological Evaluations',
      'Child Custody Evaluations',
      'Court Ordered Therapy',
      'Expert Witness',
      'Forensic Consultant',
    ],
  },
  {
    name:     'Steven E “Jake” Jacobson',
    image:    require('../images/headshots/jake-jacobson.jpg'),
    license:  ['Licensed Clinical Social Worker'],
    website:  ['ccdcounseling.com'],
    email:    'jake@ccdcounseling.com',
    phone:    '940-314-3444',
    services: [
      'Mediation',
      'Parenting Plan Design/Consultation',
      'Co-Parent Education',
      'Co-Parent Support',
      'Counseling',
    ],
  },
  {
    name:     'Jane Phillips, Ph.D., LCSW-S',
    image:    require('../images/headshots/jane-philips.jpeg'),
    license:  ['Licensed Clinical Social Worker-Supervisor'],
    website:  ['drjanephillips.com'],
    email:    'janephillips@drjanephillips.com',
    phone:    '682-225-6990',
    services: [
      'Court Ordered Therapy',
      'Pre-Divorce Parent Consultation',
      'Co-Parent Education',
      'Reunification Therapy',
      'Expert Witness',
      'Forensic Consultant',
    ],
  },
  {
    name:     'Aaron Robb, Ph.D., LPC-S',
    image:    require('../images/headshots/aaron-robb.png'),
    license:  ['Licensed Professional Counselor-Supervisor'],
    website:  ['TexasCounseling.org'],
    email:    null,
    phone:    '972-360-7437',
    services: [
      'Child Custody Evaluations',
      'Adoption Evaluations',
      'Guardian Ad Litem Appointments',
      'Adult & Family Therapy',
      'Parenting Facilitation',
      'Supervised Visitation',
      'Expert Witness',
      'Forensic Consultant',
    ],
  },
  {
    name:     'Kristina Vellucci-Cook, Psy.D.',
    image:    require('../images/headshots/kristina-vellucci-cook.jpeg'),
    license:  ['Licensed Psychologist (California & Texas)', 'Licensed Marriage and Family Therapist (California)'],
    website:  ['drkvcook.com'],
    email:    'kristina@drkvcook.com',
    phone:    '714-457-6007',
    services: [
      'Forensic Consultant',
      'Psychological Evaluations',
      'Child Custody Evaluations (Under Supervision)',
    ],
  },
];

export default memberList;
